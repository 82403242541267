/** Libraries */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
export const SQTemplateContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  paddingBottom: "10rem",
  paddingTop: "2rem",
}));

export const TopbarContainer = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  height: "fit-content",
  zIndex: 1000,
}));

export const BannerContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
}));

export const BodyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "18px",
  height: "auto",
  justifyContent: "center",
  flexDirection: "column",
  maxWidth: "738px",
  width: "100%",
  [theme.breakpoints.down(1200)]: {
    padding: "0rem 1rem",
  },
  [theme.breakpoints.down(900)]: {
    fontFamily: "'Noto Sans', sans-serif",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export const NavigationButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "738px",
  padding: "0rem 0rem 3rem 0rem",
  display: "flex",
  gap: "1rem",
  [theme.breakpoints.down(1200)]: {
    padding: "1rem 1rem 3rem 1rem",
  },
}));

export const NavigationButtonsMainContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  zIndex: 1060,
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  display: "flex",
  justifyContent: "center",
}));

export const EmptyStyleContainer = styled(Box)(({ theme, cx }) => ({
  width: "100%",
  maxWidth: "90px",
  height: cx ? "20px" : "32px",
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down(1200)]: {
    display: "none",
  },
}));

export const NavigationButtonsMainContainerMQ = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 1060,
  position: "fixed",
  left: 0,
  // right: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down(1200)]: {
    justifyContent: "center",
  },
}));

export const NavigationButtonsContainerMQ = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "480px",
  height: "auto",
  display: "flex",
  gap: "10px",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  marginTop: "15px",
  marginRight: "4%",
  padding: "15px",
  [theme.breakpoints.up(1900)]: {
    marginRight: "13%",
  },
  [theme.breakpoints.between(1800, 1900)]: {
    marginRight: "11%",
  },
  [theme.breakpoints.between(1700, 1800)]: {
    marginRight: "9%",
  },
  [theme.breakpoints.between(1600, 1700)]: {
    marginRight: "7%",
  },
  [theme.breakpoints.down(1200)]: {
    maxWidth: "930px",
    padding: "1rem 1rem 3rem 1rem",
    marginRight: "0px",
  },
  [theme.breakpoints.down(415)]: {
    marginTop: "20px",
  },
}));

export const ButtonContainer = styled("div")(
  ({ theme, secondarybuttonexist }) => ({
    width: secondarybuttonexist ? "50%" : "100%",
  })
);
