import { styled } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
import MarkLatex from "../../../../../atom/Marklatex/MarkLatex";
import { keyframes } from "@emotion/react";

const appearFromMiddle = keyframes`
  from {
    opacity: 0.3;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const StyledContainer = styled(Container)(({ theme, exercise }) => ({
  "&&": {
    maxWidth: "1600px",
    width: "100%",
    height: "auto",
    scrollbarWidth: "thin",
    padding: "0px 32px 0px 32px",
    display: "flex !important",
    flexDirection: "row",
    animation: exercise && `${appearFromMiddle} 0.3s ease-out`,
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
      padding: "0px 16px 0px 16px",
    },
    [theme.breakpoints.up(1200)]: {
      justifyContent: "center",
      gap: "72px",
    },
    [theme.breakpoints.down(900)]: {
      marginTop: "28px",
    },
    [theme.breakpoints.down(600)]: {
      padding: "16px 8px",
      marginTop: "5px",
      gap: "5px",
    },
  },
}));

export const MQQuestionReviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "429px",
  height: "min-content",
  gap: "18px",
  [theme.breakpoints.up(1400)]: {
    maxWidth: "429px",
  },
  [theme.breakpoints.down(1200)]: {
    minWidth: "100%",
    marginTop: "30px",
  },
  [theme.breakpoints.down(600)]: {
    minWidth: "100%",
    width: "100%",
  },
}));

export const AnswerAndMultiQuestionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const StyledAnswersReviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  gap: "18px",
  maxWidth: "980px",
}));

export const AnswerCard = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  padding: "20px 20px 0px 20px",
  alignSelf: "strech",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  borderRadius: "5px",
  cursor: "pointer",
}));

export const AnswerCardText = styled(MarkLatex)(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "30px",
  "& img": {
    minHeight: "0",
  },
  [theme.breakpoints.down(900)]: {
    lineHeight: "25px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
    lineHeight: "25px",
  },
}));

export const AnswerAndImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "80px",
  display: "flex",
  paddingBottom: "0px",
  alignItems: "center",
  gap: "16px",
  flex: "1 0 0",
}));

export const TextAndButtonImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingBottom: "0px",
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "6px",
  flex: "1 0 0",
}));

export const UppgiftText = styled(Typography)(({ theme }) => ({
  alignSelf: "stretch",
  "&.MuiTypography-root": {
    color: theme.palette.appcolors.black,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "14px",
    letterSpacing: "0.96px",
    textTransform: "capitalize",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "16px",
    letterSpacing: "1.28px",
  },
}));

export const ButtonImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));
