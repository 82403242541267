/** Libraries */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { styled, useTheme } from "@mui/material/styles";

/** Axios instances */
import { EndPoints, instance2 } from "../../../../../service/Route";

/** Atoms */
import MarkLatex from "../../../../../atom/Marklatex/MarkLatex";
import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../../../../atom/CustomIcons/ArrowIcons";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";

/** Molecules */
import MultiAnswer from "../../../../../molecule/MultiAnswer/MultiAnswer";
import { AnswerReviewButtonMQ } from "../../../../../molecule/AnswerButton/AnswerButtonMQ";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";

/** Organisms */
import ExerciseBottomBar from "../../../../BottomBars/ExerciseBottomBar";

/** Tools */
import { formatText, SendSentryError } from "../../../../../../tools";

/** Utils */
import { scrollTop } from "../../../../../../utils/commonService";

/** Styles */
import {
  AnswerAndMultiQuestionsContainer,
  StyledAnswersReviewContainer,
  AnswerCard,
  AnswerCardText,
  AnswerAndImageContainer,
  TextAndButtonImageContainer,
  TextContainer,
  UppgiftText,
  ButtonImageContainer,
} from "./SharedDtkOrg.styles";
import { useAiTutorStore } from "../../../../../../hooks";

/** Material UI - Custom elements */
const MainContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 114,
  right: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "466px",
  minWidth: "466px",
  height: "min-content",
  gap: "18px",
  marginBottom: "8rem",
  [theme.breakpoints.up(1400)]: {
    maxWidth: "466px",
  },
  [theme.breakpoints.down(1200)]: {
    maxWidth: "900px",
    width: "100%",
    marginTop: "30px",
    alignItems: "flex-start",
  },
  [theme.breakpoints.down(600)]: {
    marginBottom: "5rem",
    minWidth: "100%",
    width: "100%",
  },
}));

const TransitionContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  zIndex: 1060,
}));

const ResultQuestionViewDtkOrg = (props) => {
  const { switchAIKeyPress, startAiTutorLogout } = useAiTutorStore();
  const { width } = useWindowDimensions();
  const [paragraph, setParagraph] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [enterSubmitted, setEnterSubmitted] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const handleEnterClick = (e) => {
      if (e.keyCode === 13) {
        if (!switchAIKeyPress) {
          changeQuestion();
          setEnterSubmitted(true);
        }
      }
    };
    !feedbackPopup && document.addEventListener("keydown", handleEnterClick);
    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [enterSubmitted, feedbackPopup, switchAIKeyPress]);

  const changeQuestion = () => {
    startAiTutorLogout();
    scrollTop();
    props.startTimer();
    props.nextQuestion();
  };

  useEffect(() => {
    setShowLoader(true);
    const data = {
      quiz: props?.quizId,
    };

    const URL = EndPoints.getParagraphResult + props?.paragraph?._id;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    instance2
      .post(URL, data, { headers })
      .then((response) => {
        setShowLoader(false);
        setParagraph(response.data?.question);
      })
      .catch((error) => {
        console.error("Error getting paragraph result:", error);
        SendSentryError("PARAGRAPH-RESULT", "Error getting paragraph result", {
          details: "Failed to getting paragraph result",
          error,
        });
      });
  }, []);

  const showResult = (index) => {
    const quiz = [...paragraph];
    let question = quiz[index];
    if (question?.showResult) {
      question.showResult = false;
      setParagraph(quiz);
    } else {
      question.showResult = true;
      setParagraph(quiz);
    }
  };

  const handleAnswerBorder = (isCorrect) => {
    if (isCorrect) {
      return `3px solid ${theme.palette.appcolors.success4}`;
    } else {
      return `3px solid ${theme.palette.appcolors.error6}`;
    }
  };

  return (
    <>
      {showLoader ? (
        <MainContainer>
          <CircularProgress />
        </MainContainer>
      ) : (
        <MainContainer>
          <StyledAnswersReviewContainer>
            {paragraph &&
              paragraph?.map((item, index) => {
                return (
                  <AnswerAndMultiQuestionsContainer key={item._id}>
                    <AnswerCard
                      sx={{
                        border: handleAnswerBorder(
                          item.optionId === item?.answer?.option
                        ),
                      }}
                      onClick={() => showResult(index)}
                    >
                      <TextAndButtonImageContainer>
                        <AnswerAndImageContainer>
                          {width > 600 && (
                            <>
                              {item.optionId === item?.answer?.option ? (
                                <StyledCorrectIcon />
                              ) : (
                                <StyledWrongIcon />
                              )}
                            </>
                          )}
                          <TextContainer>
                            <UppgiftText>
                              {"Uppgift " +
                                `${index + 1}` +
                                " av " +
                                paragraph?.length}
                            </UppgiftText>
                            {item?.questionStatement?.includes(
                              "hp-appen.s3.eu-north-1.amazonaws.com"
                            ) ? (
                              <Typography
                                variant="h6"
                                component="h6"
                                className="questionImage"
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "600",
                                }}
                              >
                                <MarkLatex
                                  content={formatText(item.questionStatement)}
                                />
                              </Typography>
                            ) : (
                              <AnswerCardText
                                content={formatText(item.questionStatement)}
                              />
                            )}
                          </TextContainer>
                        </AnswerAndImageContainer>
                        <ButtonImageContainer>
                          {item.showResult ? (
                            <UpArrowIcon />
                          ) : (
                            <DownArrowIcon />
                          )}
                        </ButtonImageContainer>
                      </TextAndButtonImageContainer>
                    </AnswerCard>

                    <Collapse
                      sx={{ width: "100%" }}
                      orientation="vertical"
                      in={item.showResult}
                    >
                      <MultiAnswer
                        isAiEnabled={true}
                        indexMQ={index}
                        question={item}
                        selectOption={item.optionId}
                        feedbackPopup={feedbackPopup}
                        setFeedbackPopup={setFeedbackPopup}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                      />
                    </Collapse>
                  </AnswerAndMultiQuestionsContainer>
                );
              })}
          </StyledAnswersReviewContainer>

          {/* <AnswerReviewButtonMQ changeQuestion={() => changeQuestion()} />*/}
          <TransitionContainer>
            <ExerciseBottomBar
              mq
              mqresult
              changeQuestion={() => changeQuestion()}
            />
          </TransitionContainer>
        </MainContainer>
      )}
    </>
  );
};

export default ResultQuestionViewDtkOrg;
