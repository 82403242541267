// Prices.js
// Highest order global price - Change price here. This will affect Checkout and other CTAs.

const planOne = {
  name: "Till höstens prov",
  price: 490,
  premiumLength: 12, //always in months, in this case 3 months
  pricePerMonth: 490 / 12, //Just for display on frontend, do not store in backend
  id: "planOne",
  discount: null,
  popular: false,
  guarantee: false,
  isGroupOrder: false,
};

const planTwo = {
  name: "Hela HP-appen i 12 månader",
  price: 989,
  premiumLength: 12,
  pricePerMonth: 989 / 12, //Just for display on frontend, do not store in backend
  id: "planTwo",
  discount: "15%",
  popular: true,
  guarantee: true,
  isGroupOrder: false,
};

const planThree = {
  name: "Verbal eller kvantitativ 12 mån",
  price: 569,
  premiumLength: 12,
  pricePerMonth: 1068 / 12, //Just for display on frontend, do not store in backend
  id: "planThree",
  discount: null,
  popular: false,
  guarantee: true,
  isGroupOrder: false,
};

const payingFullFromPartial = {
  name: "Hela HP-appen i 12 månader",
  price: 420,
  premiumLength: 12,
  pricePerMonth: 989 / 12, //Just for display on frontend, do not store in backend
  id: "payingFullFromPartial",
  discount: "15%",
  popular: true,
  guarantee: true,
  isGroupOrder: false,
};

export default { planOne, planTwo, planThree, payingFullFromPartial };
