/** Libraries */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

/** Atoms */
import {
  StyledQuestionContainer,
  StyledQuestionMainContainer,
} from "../../../components/atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";

import {
  ButtonContainer,
  NavigationButtonsContainerMQ,
  NavigationButtonsMainContainerMQ,
} from "../../../components/template/QuizTemplates/SQTemplate/SQTemplate.styles";

/** Molecules */
// import Pagination from "../../../../molecule/Pagination/Pagination";
import QuestionStatement from "../../../components/molecule/QuestionStatement/QuestionStatement";
import { AnswerButtonMQ } from "../../../components/molecule/AnswerButton/AnswerButtonMQ";

/** Organisms */
import Options from "../../../components/organism/Quiz/Options/Options";

/** Styles */
import {
  DescriptionQuestionContainer,
  MainContainer,
  StyledOptionsContainer,
} from "../../../components/organism/Quiz/MQQuestion/MQQuestion/MQQuestion.styles";

const CustomExamsQuestionMQ = ({
  // quiz,
  question,
  selectedCurrMQIndex,
  sectionCategory,
  enableSubmitButton,
  isAnswerVisible,
  handleSelectOption,
  handlePagination,
  handleSubmitQuestions,
  primaryButton,
  secondaryButton,
  ...props
}) => {
  const questionD = useMemo(() => question, [question]);
  const isELFgaps = useMemo(
    () =>
      ["gap 31", "gap 32", "gap 33", "gap 34", "gap 35"].some((substring) =>
        question?.questionStatement.includes(substring)
      ),
    [question]
  );

  // useEffect(() => {
  //   const handleEnterClick = (e) => {
  //     e.preventDefault();
  //     if (e.keyCode === 13 && enableSubmitButton) {
  //       handleSubmitQuestions();
  //     }
  //   };
  //   document.addEventListener("keydown", handleEnterClick);

  //   return () => {
  //     document.removeEventListener("keydown", handleEnterClick);
  //   };
  // }, [handleSubmitQuestions]);

  if (!question) return null;

  return (
    <MainContainer {...props}>
      <StyledQuestionMainContainer>
        <StyledQuestionContainer>
          <DescriptionQuestionContainer>
            <QuestionStatement
              description={questionD?.questionStatement}
              iself={isELFgaps}
              indications={[questionD?.information1, questionD?.information2]}
              type={sectionCategory}
              isMQ={true}
            />
          </DescriptionQuestionContainer>

          <StyledOptionsContainer disableGutters>
            <Options
              options={questionD?.options.options}
              answerId={questionD?.answerObj?.option}
              answerExist={isAnswerVisible}
              selectedOption={questionD?.optionId}
              handleSelectOption={handleSelectOption}
              areBorderColorsVisible={false}
            />
          </StyledOptionsContainer>
        </StyledQuestionContainer>
      </StyledQuestionMainContainer>
      <NavigationButtonsMainContainerMQ>
        <NavigationButtonsContainerMQ>
          <ButtonContainer secondarybuttonexist={!!secondaryButton}>
            {primaryButton}
          </ButtonContainer>
          {!!secondaryButton && (
            <ButtonContainer secondarybuttonexist={!!secondaryButton}>
              {secondaryButton}
            </ButtonContainer>
          )}
        </NavigationButtonsContainerMQ>
      </NavigationButtonsMainContainerMQ>
    </MainContainer>
  );
};

export default CustomExamsQuestionMQ;

// const optionShape = PropTypes.shape({
//   _id: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired,
//   image: PropTypes.string,
//   type: PropTypes.string,
// });

// const questionShape = PropTypes.shape({
//   _id: PropTypes.string.isRequired,
//   questionStatement: PropTypes.string.isRequired,
//   questionId: PropTypes.string.isRequired,
//   options: PropTypes.object({
//     options: PropTypes.arrayOf(optionShape).isRequired,
//   }).isRequired,
// });

// MQQuestion.propTypes = {
//   question: PropTypes.arrayOf(questionShape).isRequired,
//   selectedCurrMQIndex: PropTypes.number.isRequired,
//   sectionCategory: PropTypes.string.isRequired,
//   enableSubmitButton: PropTypes.bool,
//   isAnswerVisible: PropTypes.bool,
//   handleSelectOption: PropTypes.func.isRequired,
//   handlePagination: PropTypes.func.isRequired,
//   handleSubmitQuestions: PropTypes.func.isRequired,
// };

// MQQuestion.defaultProps = {
//   enableSubmitButton: true,
//   isAnswerVisible: false,
// };
